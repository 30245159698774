*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

body {
	padding: 0;
	margin: 0;
}

.logo {
	position: absolute;
	left: 60px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}

.contact {
	position: absolute;
	right: 60px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	cursor: pointer;
}

#contact {
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: #ffffff;
	display: none;
	z-index: 10;
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 800px;
		height: 400px;
		.title {
			position: absolute;
			top: -60px;
			left: 50%;
			transform: translateX(-50%);
			font-family: Helvetica, Arial, sans-serif;
			font-size: 20px;
			color: #000000;
			text-align: center;
		}
		.info {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			font-family: Helvetica, Arial, sans-serif;
			font-size: 16px;
			color: #000000;
			text-align: center;
		}
	}
}

#content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 800px;
	height: 400px;
	.box {
		position: relative;
		width: 25%;
		height: 50%;
		font-family: Helvetica, Arial, sans-serif;
		font-size: 20px;
		color: #000000;
		text-align: center;
		line-height: 200px;
		float: left;
		cursor: pointer;
		z-index: 2;
		overflow: hidden;
		img {
			width: 100%;
			transition: 1s ease;
			&:hover {
				transform: scale(1.2);
			}
		}
	}
	.detail-1 {
		position: absolute;
		top: -5px;
		left: -5px;
		z-index: 1;
	}
	.detail-2 {
		position: absolute;
		bottom: -3px;
		right: -3px;
		z-index: 1;
	}
}

#about {
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: #ffffff;
	display: none;
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 800px;
		height: 400px;
		.title {
			position: absolute;
			top: -60px;
			left: 50%;
			transform: translateX(-50%);
			font-family: Helvetica, Arial, sans-serif;
			font-size: 20px;
			color: #000000;
			text-align: center;
		}
		.info {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			font-family: Helvetica, Arial, sans-serif;
			font-size: 16px;
			color: #000000;
			text-align: center;
		}
	}
}

.close {
	position: absolute;
	bottom: -60px;
	left: 50%;
	transform: translateX(-50%);
	font-family: Helvetica, Arial, sans-serif;
	font-size: 26px;
	color: #000000;
	text-align: center;
	cursor: pointer;
}

#three-d {
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: #ffffff;
	z-index: 11;
	display: none;
	.title {
		position: absolute;
		left: 60px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
	}
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 800px;
		height: 400px;
		.half {
			position: relative;
			width: 50%;
			height: 100%;
			float: left;
			cursor: pointer;
			overflow: hidden;
			text-align: center;
			img {
				width: 100%;
				transform: translateX(-22%);
				transition: 1s ease;
				&:hover {
					transform: translateX(-25%) scale(1.2);
				}
			}
		}
	}
}

#collages {
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: #ffffff;
	z-index: 11;
	display: none;
	.title {
		position: absolute;
		left: 60px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
	}
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 800px;
		height: 400px;
		.quarter {
			position: relative;
			width: 25%;
			height: 100%;
			float: left;
			cursor: pointer;
			overflow: hidden;
			text-align: center;
			img {
				height: 100%;
				transform: translateX(-22%);
				transition: 1s ease;
				&:hover {
					transform: translateX(-25%) scale(1.2);
				}
			}
		}
	}
}

#typo {
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: #ffffff;
	z-index: 11;
	display: none;
	.title {
		position: absolute;
		left: 60px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
	}
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 800px;
		height: 400px;
		.quarter {
			position: relative;
			width: 25%;
			height: 50%;
			float: left;
			cursor: pointer;
			overflow: hidden;
			text-align: center;
			background-color: #000000;
			img {
				width: 100%;
				transition: 1s ease;
				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}
}